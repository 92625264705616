/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "1.0.0 (2023-02-28)"), "\n", React.createElement(_components.h3, null, "Features"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "initial release (", React.createElement(_components.a, {
    href: "https://github.com/goldenpathtechnologies/react-tippin-button/commit/555c97d02582ecbc8ad055b165a6949048e3a46e"
  }, "555c97d"), ")"), "\n"), "\n", React.createElement(_components.h3, null, "Bug Fixes"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "resolved a11y issues with Tippin modal components (", React.createElement(_components.a, {
    href: "https://github.com/goldenpathtechnologies/react-tippin-button/commit/d10f1772026e5261b2c36dc70fb50aa0783740d2"
  }, "d10f177"), ")"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;

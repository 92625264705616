/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import TippinButton from "../../external/index";
import logo from "../images/gpt-logo.png";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h3: "h3",
    p: "p",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "React Tippin.me Web Button Demo"), "\n", React.createElement(_components.h3, null, "Styling with 'className' attribute and TailwindCSS classes"), "\n", React.createElement(TippinButton, {
    userName: "GoldenPathTech",
    className: "p-4 font-bold text-2xl border-amber-400 bg-amber-300 hover:bg-amber-400 border-2 rounded-2xl"
  }, React.createElement(_components.p, null, "Tippin.me")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "<TippinButton\n    userName=\"GoldenPathTech\"\n    className=\"p-4 font-bold text-2xl border-amber-400 bg-amber-300 hover:bg-amber-400 border-2 rounded-2xl\"\n>\n    Tippin.me\n</TippinButton>\n")), "\n", React.createElement(_components.h3, null, "Styling with 'style' attribute"), "\n", React.createElement(TippinButton, {
    userName: "GoldenPathTech",
    style: {
      backgroundColor: `lightsalmon`,
      borderWidth: `2px`,
      borderColor: `lightcoral`,
      borderRadius: `10px`,
      padding: `5px`,
      fontWeight: `bold`,
      fontSize: `2rem`
    }
  }, React.createElement(_components.p, null, "Tippin.me")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "<TippinButton\n    userName=\"GoldenPathTech\"\n    style={{\n        backgroundColor: `lightsalmon`,\n        borderWidth: `2px`,\n        borderColor: `lightcoral`,\n        borderRadius: `10px`,\n        padding: `5px`,\n        fontWeight: `bold`,\n        fontSize: `2rem`,\n    }}\n>\n    Tippin.me\n</TippinButton>\n")), "\n", React.createElement(_components.h3, null, "Tippin button as an image"), "\n", React.createElement(TippinButton, {
    userName: "GoldenPathTech"
  }, React.createElement("img", {
    src: logo,
    alt: "Golden Path Technologies logo as Tippin.me button"
  })), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "import logo from \"../images/gpt-logo.png\";\n\n// ...\n\n<TippinButton userName=\"GoldenPathTech\">\n    <img src={logo} alt=\"Golden Path Technologies logo as Tippin.me button\" />\n</TippinButton>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;

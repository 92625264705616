/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    img: "img",
    a: "a",
    h2: "h2",
    pre: "pre",
    code: "code",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, React.createElement("span", null, "⚡"), " React Tippin.me Web Button ", React.createElement("span", null, "⚡")), "\n", React.createElement(_components.p, null, "The Tippin.me web button reimagined as a customizable React component. Easily accept Bitcoin payments via the Lightning\nNetwork in your React applications."), "\n", React.createElement(_components.img, {
    src: "https://img.shields.io/github/package-json/v/goldenpathtechnologies/react-tippin-button",
    alt: "GitHub package.json version"
  }), "\n", "\n", "\n", React.createElement(_components.a, {
    href: "https://github.com/goldenpathtechnologies/react-tippin-button/actions/workflows/release.yml"
  }, React.createElement(_components.img, {
    src: "https://github.com/goldenpathtechnologies/react-tippin-button/actions/workflows/release.yml/badge.svg",
    alt: "Release"
  })), "\n", "\n", "\n", React.createElement(_components.a, {
    href: "https://github.com/goldenpathtechnologies/react-tippin-button/actions/workflows/docs.yml"
  }, React.createElement(_components.img, {
    src: "https://github.com/goldenpathtechnologies/react-tippin-button/actions/workflows/docs.yml/badge.svg",
    alt: "Deploy Documentation Site to Pages"
  })), "\n", "\n", "\n", React.createElement(_components.a, {
    href: "https://conventionalcommits.org"
  }, React.createElement(_components.img, {
    src: "https://img.shields.io/badge/Conventional%20Commits-1.0.0-yellow.svg",
    alt: "Conventional Commits"
  })), "\n", "\n", "\n", React.createElement(_components.img, {
    src: "https://img.shields.io/github/license/goldenpathtechnologies/react-tippin-button",
    alt: "GitHub"
  }), "\n", "\n", "\n", React.createElement(_components.a, {
    href: "https://tippin.me/@GoldenPathTech"
  }, React.createElement(_components.img, {
    src: "https://badgen.net/badge/%E2%9A%A1%EF%B8%8Ftippin.me/@GoldenPathTech/F0918E",
    alt: "tippin.me"
  })), "\n", React.createElement(_components.h2, null, React.createElement("span", null, "🎬"), " Demo"), "\n", React.createElement(_components.p, null, "See the improved Tippin.me web button in action ", React.createElement(_components.a, {
    href: "https://react-tippin-button.goldenpath.ca/demo/"
  }, "here"), "."), "\n", React.createElement(_components.h2, null, React.createElement("span", null, "🛠"), " Installation"), "\n", React.createElement(_components.p, null, "PNPM:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "pnpm add react-tippin-button\n")), "\n", React.createElement(_components.p, null, "NPM:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm install react-tippin-button\n")), "\n", React.createElement(_components.p, null, "Yarn:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "yarn install react-tippin-button\n")), "\n", React.createElement(_components.h2, null, React.createElement("span", null, "👨‍💻"), " Basic usage"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "import TippinButton from \"react-tippin-button\";\n\n// ...\n\n<TippinButton\n    userName=\"TippinUserName\"\n    className=\"CSS Classes\"\n    style={{ backgroundColor: `blue` }}\n>\n    Enter text, images, etc.\n</TippinButton>\n")), "\n", React.createElement(_components.p, null, "See the ", React.createElement(_components.a, {
    href: "https://react-tippin-button.goldenpath.ca/demo/"
  }, "demo"), " for more examples."), "\n", React.createElement(_components.h2, null, React.createElement("span", null, "📋"), " Properties"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Attribute"), React.createElement(_components.th, null, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, "userName (required)"), React.createElement(_components.td, null, "[string] Your Tippin.me user name")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "className"), React.createElement(_components.td, null, "[string] CSS class attribute for the Tippin button only, other UI elements can not be customized")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "style"), React.createElement(_components.td, null, "[object] CSS style attribute for the Tippin button only, other UI elements can not be customized")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "subPath"), React.createElement(_components.td, null, "[string] The root URL for the Tippin.me service, defaults to \"", React.createElement(_components.a, {
    href: "https://tippin.me/"
  }, "https://tippin.me/"), "\" (this should not be changed in normal usage)")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "children (required)"), React.createElement(_components.td, null, "[string or JSX.Element] The Tippin button tag can not be empty and must contain text or another component")))), "\n", React.createElement(_components.h2, null, React.createElement("span", null, "🤦🏿‍♂️"), " Issues"), "\n", React.createElement(_components.p, null, "Please report any issues with this software\n", React.createElement(_components.a, {
    href: "https://github.com/goldenpathtechnologies/react-tippin-button/issues"
  }, "here"), ". If you would like to contribute to\nthis project, feel free to fork it and send a pull request. Note that this project is governed by a\n", React.createElement(_components.a, {
    href: "https://github.com/goldenpathtechnologies/react-tippin-button/blob/main/CODE_OF_CONDUCT.md"
  }, "code of conduct"), "."), "\n", React.createElement(_components.h2, null, React.createElement("span", null, "💡"), " Motivation"), "\n", React.createElement(_components.p, null, "The idea for this project came to me as I was attempting to integrate the Tippin.me web button in another React\nproject. This required me to reverse engineer some parts of the web button. The work piled up to the point where it\nmade sense to make the React Tippin Button into a separate library, but it was such a minor feature that it didn't\nmake sense to do so. I didn't have a lot of experience publishing NPM packages, so I decided to go all out on this\none to simultaneously create a template for future libraries that include a project documentation site. If you don't\nfind the Tippin Button particularly useful, this project should also serve as a useful template for creating high\nquality libraries that are publishable on NPM."), "\n", React.createElement(_components.h2, null, React.createElement("span", null, "📃"), " License"), "\n", React.createElement(_components.p, null, "This project is ", React.createElement(_components.a, {
    href: "https://github.com/goldenpathtechnologies/react-tippin-button/blob/main/LICENSE"
  }, "MIT"), "\nlicensed."), "\n", React.createElement(_components.h2, null, React.createElement("span", null, "⚖"), " Legal notice"), "\n", React.createElement(_components.p, null, "The Tippin.me logo, image assets, Tippin card, and all associated branding are properties owned by\nTippin Group. Golden Path Technologies Inc. is in no way affiliated with Tippin Group. Users of this\nsoftware must agree to the terms of the ", React.createElement(_components.a, {
    href: "https://tippin.me/PrivacyPolicy"
  }, "Tippin.me privacy policy"), ". Do\nnot use this software if you do not agree to these terms."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
